<template>
  <div id="print">
    <p style="text-align: center; font-weight: 600; font-size: 18px">进销存调整单</p>
    <div style="margin-bottom: 10px">
      <a-row>
        <a-col :span="8" style="display: flex;">
          <div>进销存单号：</div>
          <div style="flex: 1;">{{ orderData.business_order_num }}</div>
        </a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          SAP号：{{ orderData.fms_sap_num }}
        </a-col>
      </a-row>
    </div>
    <div v-for="detail in orderData.sale_adjust_order.sale_adjust_details" :key="detail.id">
      <p style="text-align: left; font-weight: 600">
        <a-divider orientation="left">
          {{ detail.adjust_type }}
        </a-divider>
      </p>
      <table v-if="detail.adjust_type === '删除费用'|| detail.adjust_type === '新增费用'">
        <tbody>
          <tr>
            <th>货物名称</th>
            <th>单位</th>
            <th>规格</th>
            <th>数量</th>
            <th>进货单价</th>
            <th>进货总价</th>
            <th>进货总价（去税）</th>
            <th>出货单价</th>
            <th>出货总价</th>
            <th>供应商</th>
            <th>备注</th>
          </tr>
          <tr v-for="item in detail.delete_good_list" :key="item.goodId">
            <td>{{ item.good_name }}</td>
            <td>{{ item.good_unit }}</td>
            <td>{{ item.good_spec }}</td>
            <td>{{ item.good_num }}</td>
            <td>{{ item.price_in }}</td>
            <td>{{ item.price_in*item.good_num }}</td>
            <td>{{ parseFloat((item.price_in_tax*item.good_num).toFixed(2)) }}</td>
            <td>{{ item.price_out }}</td>
            <td>{{ item.price_out**item.good_num }}</td>
            <td>{{ item.supplier_name }}</td>
            <td>{{ item.remark }}</td>
          </tr>
          <tr v-for="item in detail.add_good_list" :key="item.id">
            <td>{{ item.good_name }}</td>
            <td>{{ item.good_unit }}</td>
            <td>{{ item.good_spec }}</td>
            <td>{{ item.num }}</td>
            <td>{{ item.price_in }}</td>
            <td>{{ item.price_in*item.num }}</td>
            <td>{{ parseFloat((item.price_in_tax*item.num).toFixed(2)) }}</td>
            <td>{{ item.price_out }}</td>
            <td>{{ item.price_out*item.num }}</td>
            <td>{{ item.supplier_name }}</td>
            <td>{{ item.remark }}</td>
          </tr>
        </tbody>
      </table>
      <table v-if="detail.adjust_type === '修改客户'">
        <tbody>
          <tr>
            <th>原客户</th>
            <th>新客户</th>
          </tr>
          <tr>
            <td class="w100">{{ detail.original_customer_name }}</td>
            <td class="w100">{{ detail.new_customer_name }}</td>
          </tr>
        </tbody>
      </table>
      <table v-if="detail.adjust_type === '重新开票'">
        <tbody>
          <tr>
            <th>原票号</th>
            <th>新票号</th>
          </tr>
          <tr>
            <td class="w100">{{ detail.original_invoices }}</td>
            <td class="w100">{{ detail.new_invoices }}</td>
          </tr>
        </tbody>
      </table>
      <div v-if="detail.adjust_type === '作废发票(不重开)'">
        <span>作废票号：{{ detail.original_invoices }}</span>
      </div>
      <table v-if="detail.adjust_type === '修改代理费'">
        <tbody>
          <tr>
            <th>原代理费</th>
            <th>新代理费</th>
          </tr>
          <tr>
            <td class="w100">{{ detail.original_agency_fee }}</td>
            <td class="w100">{{ detail.new_agency_fee }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="orderData.adjust_flow_list">
      <!-- <p style="text-align: left; font-weight: 600">
        <a-divider orientation="left"> 审核信息 </a-divider>
      </p> -->
      <a-row :gutter="24">
        <a-col :span="24">
          调整原因:{{ orderData.reason_description}}
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="4" v-for="flow in orderData.adjust_flow_list" :key="flow.id">
          {{flow.flow_status_name}}：{{flow.flow_user_name}}-{{ moment(flow.created_at).format('YYYY/MM/DD') }}
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: `SaleAdjustBill`,
  components: {},
  props: {
    currentData: { type: Object, default: null }
    // balanceData: { type: Array, default: null }
  },
  created() {
    this.initData();
  },
  watch: {
    currentData(val, oldVal) {
      this.orderData = val
    }
    // balanceData: {
    //   handler(val, oldVal) {
    //     this.initData();
    //   },
    //   deep: true
    // }
  },
  data() {
    return {
      orderData: {},
      orderList: [],
      userInfo: this.$store.getters.userInfo,
      date: moment().year() + '-' + (moment().month() + 1) + '-' + moment().date()
    }
  },
  methods: {
    moment,
    initData() {
      this.orderData = this.currentData
      // this.orderList = this.balanceData
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 0px auto;
}

@media print {
  html, body {
    height: inherit;
  }
}

.title{
  width: 100%;
}

.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}

#print { margin: 0 auto; padding: 20px}
#print p, #print table { width: 100% }
table { border-collapse: collapse }
.h100 { height:100px }
td, th { padding: 5px; border: 1px solid #ccc;text-align: center; }
td:nth-child(1), td:nth-child(2) { text-align: center }
td:nth-child(5) { text-align: right }
td:nth-child(6) { text-align: right }
#footer span { display: inline-block; width: 20% }
#footer span:last-child { width: 30% }
</style>
